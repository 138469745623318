<template>
  <div>
    <b-modal
      id="add-filiale"
      ref="modal"
      header-class="headerModal"
      centered
      :title="$t('AddStore')"
      size="lg"
      hide-footer
      @close="onCancel"
    >
      <b-form>
        <b-tabs
          v-model="tabIndex"
        >
          <b-tab
            :title="$t('StoreInfo')"
          >
            <template #title>
              <strong style="font-weight: 500; color: black">{{ $t("StoreInfo") }}</strong>
            </template>
            <div
              class="d-flex"
            >
              <b-form-group
                id="input-group-1"
                :label="$t('StoreName')"
                label-for="input-1"
              >
                <b-form-input
                  id="input-1"
                  v-model="$v.filiale.name.$model"
                  :state="validateState('name')"
                  aria-describedby="input-1-live-feedback"
                />
                <b-form-invalid-feedback id="input-1-live-feedback">{{
                  $t("This_is_a_required_field_and_must_be_at_least_3_letters")
                }}</b-form-invalid-feedback>
              </b-form-group>
              <b-form-group
                id="input-group-1"
                :label="$t('LocationNumber')"
                label-for="input-1"
              >
                <b-form-input
                  id="input-3"
                  v-model="$v.filiale.gln.$model"
                  :state="validateState('gln')"
                  aria-describedby="input-1-live-feedback"
                />
                <b-form-invalid-feedback id="input-1-live-feedback">{{
                  $t("This_is_a_required_field_and_must_be_at_least_3_letters")
                }}</b-form-invalid-feedback>
              </b-form-group>
            </div>

            <div class="d-flex">
              <b-form-group
                id="input-group-1"
                :label="$t('Email')"
                label-for="input-1"
              >
                <b-form-input
                  id="input-4"
                  v-model="$v.filiale.email.$model"
                  :state="validateState('email')"
                  aria-describedby="input-1-live-feedback"
                />
                <b-form-invalid-feedback id="input-1-live-feedback">{{
                  $t("This_is_a_required_field_and_must_be_email_format")
                }}</b-form-invalid-feedback>
              </b-form-group>
              <b-form-group
                id="input-group-6"
                :label="`${$t('PhoneNumber')}:`"
                label-for="input-9"
              >
                <vue-phone-number-input
                  id="input-5"
                  v-model="filiale.phone"
                  default-country-code="XK"
                  :error="isPhoneNumberValid == null ? false : isPhoneNumberValid == false ? true : false"
                  :preferred-countries="['AL', 'XK', 'DE']"
                  :state="validateState('phone')"
                  @update="getFormattedNumber"
                />
                <b-form-invalid-feedback id="input-1-live-feedback">{{
                  $t("This_is_a_required_field_and_must_be_at_least_3_letters")
                }}</b-form-invalid-feedback>
              </b-form-group>
            </div>
            <div class="d-flex">
              <b-form-group
                id="input-group-2"
                :label="$t('Manager')"
                label-for="input-2"
                style="width:49%"
              >
                <vue-select
                  id="input-12"
                  v-model="filiale.managerId"
                  :options="getUsersByRole"
                  label="fullName"
                  :reduce="(e) => e.userID"
                  :placeholder="$t('Select')"
                  aria-describedby="input-1-live-feedback"
                />
              </b-form-group>
            </div>
          </b-tab>
          <pre />
          <b-tab
            :title="$t('AddressInformation')"
          >
            <template #title>
              <strong style="font-weight: 500; color: black">{{ $t("AddressInformation") }}</strong>
            </template>
            <div class="d-flex">
              <b-form-group
                id="input-group-1"
                :label="$t('Street')"
                label-for="input-1"
              >
                <b-form-input
                  id="input-6"
                  v-model="$v.filiale.street.$model"
                  :state="validateState('street')"
                  aria-describedby="input-1-live-feedback"
                />
                <b-form-invalid-feedback id="input-1-live-feedback">{{
                  $t("This_is_a_required_field_and_must_be_at_least_3_letters")
                }}</b-form-invalid-feedback>
              </b-form-group>
              <b-form-group
                id="input-group-1"
                :label="$t('HouseNumber')"
                label-for="input-1"
              >
                <b-form-input
                  id="input-7"
                  v-model="$v.filiale.houseNumber.$model"
                  :state="validateState('houseNumber')"
                  aria-describedby="input-1-live-feedback"
                />
                <b-form-invalid-feedback id="input-1-live-feedback">{{
                  $t("This_is_a_required_field_and_must_be_at_least_3_letters")
                }}</b-form-invalid-feedback>
              </b-form-group>
            </div>
            <div class="d-flex">
              <b-form-group
                id="input-group-1"
                :label="$t('DoorNumber')"
                label-for="input-1"
              >
                <b-form-input
                  id="input-8"
                  v-model="$v.filiale.doorNumber.$model"
                  :state="validateState('doorNumber')"
                  aria-describedby="input-1-live-feedback"
                />
                <b-form-invalid-feedback id="input-1-live-feedback">{{
                  $t("This_is_a_required_field_and_must_contains_numbers")
                }}</b-form-invalid-feedback>
              </b-form-group>
              <b-form-group
                id="input-group-1"
                :label="$t('ZipCode')"
                label-for="input-1"
              >
                <b-form-input
                  id="input-9"
                  v-model="$v.filiale.postalCode.$model"
                  :state="validateState('postalCode')"
                  aria-describedby="input-1-live-feedback"
                />
                <b-form-invalid-feedback id="input-1-live-feedback">{{
                  $t("This_is_a_required_field_and_must_be_at_least_3_letters")
                }}</b-form-invalid-feedback>
              </b-form-group>
            </div>
            <div class="d-flex">
              <b-form-group
                id="input-group-1"
                :label="$t('City')"
                label-for="input-1"
              >
                <b-form-input
                  id="input-10"
                  v-model="$v.filiale.city.$model"
                  :state="validateState('city')"
                  aria-describedby="input-1-live-feedback"
                />
                <b-form-invalid-feedback id="input-1-live-feedback">{{
                  $t("This_is_a_required_field_and_must_be_at_least_3_letters")
                }}</b-form-invalid-feedback>
              </b-form-group>
              <b-form-group
                id="input-group-1"
                :label="$t('Country')"
                label-for="input-1"
              >
                <b-form-input
                  id="input-11"
                  v-model="$v.filiale.country.$model"
                  :state="validateState('country')"
                  aria-describedby="input-1-live-feedback"
                  readonly
                />
                <b-form-invalid-feedback id="input-1-live-feedback">{{
                  $t("This_is_a_required_field_and_must_be_at_least_3_letters")
                }}</b-form-invalid-feedback>
              </b-form-group>
            </div>
          </b-tab>
        </b-tabs>
      </b-form>
      <div class="buttonsEverywhere">
        <b-button
          v-show="tabIndex ==1"
          type="button"
          variant="none"
          class="buttonSubmit"
          @click="onSubmit"
        >
          {{ $t("Submit") }}
        </b-button>
        <b-button
          v-show="tabIndex !== 1"
          type="button"
          variant="none"
          class="buttonSubmit"
          :disabled="isPhoneNumberValid == false || isPhoneNumberValid == null"
          @click="tabIndex++"
        >
          {{ $t("Next") }}
        </b-button>
        <b-button
          v-show="tabIndex !== 0"
          type="button"
          variant="danger"
          class="buttonCancel"
          style="margin-right: 15px"
          @click="tabIndex--"
        >
          {{ $t("Previous") }}
        </b-button>
        <b-button
          v-show="tabIndex ==0 || tabIndex ==1"
          type="button"
          variant="danger"
          class="buttonCancel"
          style="margin-right: 15px"
          @click="onCancel"
        >
          {{ $t("Cancel") }}
        </b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { validationMixin } from 'vuelidate';
import {
  required,
  minLength,
  // alpha,
  minValue,
  numeric,
  email,
} from 'vuelidate/lib/validators';
import { mapGetters, mapActions } from 'vuex';

export default {
  components: {},
  mixins: [validationMixin],
  data() {
    return {
      userId: null,
      teamLeader: null,
      coTeamLeader: null,
      managerId: null,
      tabIndex: 0,
      formSubmitted: false,
      isPhoneNumberValid: null,
      config: {
        altInput: true,
        altFormat: 'd-m-Y',
        dateFormat: 'Y-m-d',
      },
      phone: '',
      filiale: {
        name: '',
        gln: '',
        countryCode: 'XK',
        phone: '',
        email: '',
        street: '',
        houseNumber: '',
        doorNumber: '',
        postalCode: '',
        city: '',
        country: 'Kosovo',
        countryId: null,
        countryStateId: null,
        countryState: 'Kosovo',
        managerId: null,
        teamLeaderId: null,
        coTeamLeaderId: null,
      },
    };
  },
  validations: {
    filiale: {
      name: {
        required,
        minLength: minLength(3),
      },
      gln: {
        required,
        minLength: minLength(3),
      },
      phone: {
        required,
      },
      email: {
        required,
        email,
      },
      street: {
        required,
        minLength: minLength(3),
      },
      postalCode: {
        required,
        minLength: minLength(3),
      },
      doorNumber: {
        required,
        minLength: minValue(1),
        numeric,
      },
      houseNumber: {
        required,
        minLength: minLength(1),
      },
      country: {
        minLength: minLength(3),
      },
      city: {
        required,
        minLength: minLength(3),
      },
    },
  },
  computed: {
    ...mapGetters(['getUsersByRole']),
  },
  watch: {
  },
  mounted() {
    this.usersByRole({ personRole: 'Manager' })
  },
  methods: {
    ...mapActions(['usersByRole']),
    validateState(name) {
      const { $dirty, $error } = this.$v.filiale[name];
      return $dirty ? !$error : null;
    },
    onSubmit() {
      this.formSubmitted = true;
      this.$v.filiale.$touch();
      if (this.$v.filiale.$anyError) {
        return;
      }
      this.$emit('filiale', this.filiale);
      this.$refs.modal.hide();
      this.onReset();
      setTimeout(() => {
        this.$v.$reset();
      }, 0);
      this.$nextTick(() => {
        this.$v.$reset();
      });
    },
    onCancel() {
      this.$refs.modal.hide();
      setTimeout(() => {
        this.$v.$reset();
      }, 0);
      this.$nextTick(() => {
        this.$v.$reset();
      });
      this.onReset();
    },
    getFormattedNumber(n) {
      if (n.isValid == true) {
        this.isPhoneNumberValid = true
        this.filiale.phone = n.nationalNumber
        this.filiale.countryCode = n.countryCallingCode
      } else {
        this.filiale.countryCode = ''
        this.isPhoneNumberValid = false
        return;
      }
      console.log()
    },
    onReset() {
      this.filiale.name = '';
      this.filiale.gln = '';
      this.filiale.phone = '';
      this.filiale.email = '';
      this.filiale.street = '';
      this.filiale.houseNumber = '';
      this.filiale.doorNumber = '';
      this.filiale.postalCode = '';
      this.filiale.countryState = 'Kosovo';
      this.filiale.country = 'Kosovo';
      this.isPhoneNumberValid = false
      this.filiale.city = '';
      this.filiale.phone = '';
      this.formSubmitted = false;
      this.tabIndex = 0;
      this.manager = null;
    },
  },
};
</script>

<style lang="scss" scoped>
.d-flex{
  display: flex;
  gap:10px;
}
.form-group{
  width:100%
}
.buttonSubmit:disabled {
  cursor: not-allowed !important;
  background-color: #FF274F;
  color: white;
  opacity: 0.5;
}
</style>
